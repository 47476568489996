interface Props
  extends Omit<
    React.SVGProps<SVGSVGElement>,
    "width" | "height" | "viewBox" | "fill"
  > {}
const Logo = (props: Props) => (
  <svg width="140" height="30" viewBox="0 0 140 30" fill="none" {...props}>
    <path
      d="M8.2241 0L10.7426 6.22758L2.91687 7.84095C0.485713 8.33993 -0.864311 6.49509 0.617389 4.81518C0.835001 4.56846 1.09558 4.36194 1.37695 4.19007L8.2241 0Z"
      fill="#A164F9"
    ></path>
    <path
      d="M0.410156 7.2754C0.410156 7.2754 1.18635 8.20129 2.91754 7.84092L10.7432 6.22754L20.3875 29.9999L0.410156 7.2754Z"
      fill="#163BD8"
    ></path>
    <path
      d="M27.3727 2.79981C28.6839 2.52121 30.2197 3.30711 30.0201 4.93989C29.9938 5.15057 29.9369 5.3571 29.8607 5.55392L20.387 29.9999L10.7441 6.22754L27.3741 2.79981H27.3727Z"
      fill="#1D99FF"
    ></path>
    <path
      d="M40.5854 9.30954C41.4295 7.77794 43.2646 6.93799 45.1289 6.93799C48.7992 6.93799 51.8859 9.85149 51.8859 14.2647C51.8859 18.6779 48.7992 21.5609 45.1289 21.5609C43.2646 21.5609 41.4295 20.8111 40.5854 19.249V28.0768H37.5859V7.23738H40.5854V9.30815V9.30954ZM44.7505 18.5878C46.9931 18.5878 48.8283 16.9065 48.8283 14.3243C48.8283 11.7421 46.9931 9.9402 44.7505 9.9402C42.5078 9.9402 40.6145 11.5924 40.6145 14.3243C40.6145 17.0562 42.6534 18.5878 44.7505 18.5878Z"
      fill="black"
    ></path>
    <path
      d="M65.2549 19.2484C64.3235 20.78 62.4287 21.5604 60.6823 21.5604C57.0411 21.5604 53.7783 18.7079 53.7783 14.2337C53.7783 9.75944 57.0411 6.93604 60.6823 6.93604C62.3137 6.93604 64.3235 7.62629 65.2549 9.21888V7.23681H68.2252V21.2596H65.2549V19.2484V19.2484ZM61.0316 18.5582C63.2451 18.5582 65.2258 16.967 65.2258 14.2045C65.2258 11.4421 63.0705 9.93963 61.0316 9.93963C58.7889 9.93963 56.8665 11.6209 56.8665 14.2045C56.8665 16.7882 58.7889 18.5582 61.0316 18.5582Z"
      fill="black"
    ></path>
    <path
      d="M75.7109 28.1068H72.5646L75.5072 20.5999L70.1182 7.23828H73.3227L77.1095 16.8478L80.9253 7.23828H84.1299L75.7123 28.1082L75.7109 28.1068Z"
      fill="black"
    ></path>
    <path
      d="M89.0199 21.2612H86.0205V0.0253906H89.0199V8.83936C90.1399 7.32301 91.903 6.93768 93.3306 6.93768C96.8263 6.93768 98.836 9.28013 98.836 13.2748V21.2626H95.8366V13.4549C95.8366 11.2622 94.5254 9.97177 92.8067 9.97177C91.088 9.97177 89.0213 10.9628 89.0213 13.7252V21.2626L89.0199 21.2612Z"
      fill="black"
    ></path>
    <path
      d="M115.149 15.3454H104.343C104.663 17.5077 106.294 18.8591 108.508 18.8591C110.022 18.8591 111.507 18.1383 112.207 16.8479L114.654 17.9582C113.488 20.4517 110.896 21.5619 108.362 21.5619C104.517 21.5619 101.371 18.6498 101.371 14.2061C101.371 9.76237 104.517 6.93896 108.362 6.93896C112.207 6.93896 115.179 9.76237 115.179 14.2061C115.179 14.536 115.179 15.0169 115.15 15.3468L115.149 15.3454ZM112.236 13.0931C111.916 10.9003 110.43 9.6404 108.362 9.6404C106.294 9.6404 104.663 10.9308 104.343 13.0931H112.236Z"
      fill="black"
    ></path>
    <path
      d="M120.714 21.2614H117.713V7.23725H120.714L120.716 8.73003C121.451 7.59762 122.309 7.1222 123.392 7.04458C123.99 7.00162 124.811 7.08339 125.663 7.58792L124.786 9.82918C124.492 9.68226 123.954 9.52563 123.306 9.63929C121.246 10.0011 120.712 11.9291 120.712 13.5757V21.2614H120.714Z"
      fill="black"
    ></path>
    <path
      d="M139.635 15.3454H128.829C129.149 17.5077 130.781 18.8591 132.994 18.8591C134.508 18.8591 135.994 18.1383 136.694 16.8479L139.14 17.9582C137.974 20.4517 135.382 21.5619 132.849 21.5619C129.004 21.5619 125.857 18.6498 125.857 14.2061C125.857 9.76237 129.004 6.93896 132.849 6.93896C136.694 6.93896 139.665 9.76237 139.665 14.2061C139.665 14.536 139.665 15.0169 139.636 15.3468L139.635 15.3454ZM136.723 13.0931C136.403 10.9003 134.917 9.6404 132.849 9.6404C130.781 9.6404 129.149 10.9308 128.829 13.0931H136.723Z"
      fill="black"
    ></path>
  </svg>
);
export default Logo;
