import React, { DetailedHTMLProps, LiHTMLAttributes } from "react";
import { Category } from "../../types/Categories";

interface Props
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  title: Category["title"] | string;
  isSelected?: boolean;
}

const CategoryElem = ({ title, isSelected, ...others }: Props) => {
  return (
    <li
      role="button"
      className={`p-4 px-8 inline-block whitespace-nowrap cursor-pointer font-bold select-none ${
        isSelected
          ? "text-blue-800 border-b-2 border-b-blue-800"
          : "text-stone-400 pb-[18px]"
      }`}
      {...others}
    >
      {title}
    </li>
  );
};

export default CategoryElem;
